<template>
  <UiPopup
    :model-value="modelValue"
    title="Attach image"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
  >
    <div class="overflow-hidden">
      <div
        class="mx-auto mb-2 grid w-fit gap-1 rounded-lg bg-primary-10 p-2"
        :class="{ 'grid-cols-[134px,_65px] grid-rows-2': limitedImages.length > 1 }"
      >
        <div
          v-for="(image, index) in limitedImages"
          :key="index"
          class="h-20 w-16 overflow-hidden rounded first:row-span-2 first:h-[164px] first:w-[134px]"
        >
          <img :src="convertPreviewImage(image)" alt="preview" class="size-full object-cover" />
        </div>
        <div
          v-if="images.length > 3"
          class="text-caption flex h-20 w-16 items-center justify-center rounded border border-dm-50 bg-primary-20 p-3"
        >
          {{ images.length - limitedImages.length }} more
        </div>
      </div>
      <div class="mb-5">
        <p class="text-subhead-3 mb-0.5 text-left text-black-80">Description</p>
        <textarea
          id="description"
          ref="textarea"
          v-model="input"
          rows="1"
          placeholder="Add description message to the file"
          class="text-body-2 max-h-40 min-h-10 w-full resize-none overflow-y-hidden rounded-xl border-[1.5px] border-solid border-black-20 px-4 py-2 outline-none hover:border-primary-50 focus:border-primary-120 active:border-primary-120"
        />
      </div>
      <UiButtonBase id="send-message-image" class="ml-auto" icon @click="send()">
        <UiIcon name="arrow-big-right" />
      </UiButtonBase>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'input'])
const { textarea, input } = useTextareaAutosize()

type Props = {
  modelValue: boolean
  images: any
}

const props = defineProps<Props>()

const convertPreviewImage = (image: any) => {
  return URL.createObjectURL(image)
}

const limitedImages = computed(() => {
  const imagesArray = Array.from(props.images)
  return imagesArray.length > 3 ? imagesArray.slice(0, 2) : imagesArray
})

const send = () => {
  emits('input', input.value)
  emits('update:modelValue', false)
}
</script>

<style scoped></style>
